import Vue from 'vue'
import router from '@/router'
import { fileImgMap, unknownImg, } from '@/libs/map.js'
// import { Message } from 'element-ui'

// 全局函数 - 文件相关
const fileFunction = {
	/**
	 * 格式化文件大小
	 * @param {number} size 文件大小
	 * @param {boolean} isInteger 是否只显示整数位，默认不截取
	 * @returns {string} 文件大小（带单位）
	 */
	calculateFileSize (size, isInteger = false) {
		const B = 1024
		const KB = Math.pow(1024, 2)
		const MB = Math.pow(1024, 3)
		const GB = Math.pow(1024, 4)
		if (isInteger) {
			// 截取为整数
			if (size < B) {
				return `${size}B`
			} else if (size < KB) {
				return `${(size / B).toFixed(0)} KB`
			} else if (size < MB) {
				return `${(size / KB).toFixed(0)} MB`
			} else if (size < GB) {
				return `${(size / MB).toFixed(0)} GB`
			} else {
				return `${(size / GB).toFixed(0)} TB`
			}
		} else {
			// 保留小数位
			if (size < B) {
				return `${size}B`
			} else if (size < KB) {
				return `${(size / B).toFixed(0)} KB`
			} else if (size < MB) {
				return `${(size / KB).toFixed(1)} MB`
			} else if (size < GB) {
				return `${(size / MB).toFixed(2)} GB`
			} else {
				return `${(size / GB).toFixed(3)} TB`
			}
		}
	},
	/**
	 * 根据文件扩展名设置文件图片
	 * @param {object} file 文件信息
	 */
	setFileImg (file) {
		if (file.is_dir === 1) {
			//  文件夹
			return fileImgMap.get('dir')
		} else if (fileImgMap.has(file.extend.toLowerCase())) {
			// 可以识别文件类型的文件
			return fileImgMap.get(file.extend.toLowerCase())
		} else {
			// 无法识别文件类型的文件
			return unknownImg
		}
	},
	/**
 * 使用wps进行文件预览
 * @description 若当前点击的为文件夹，则进入文件夹内部；若是文件，则进行相应的预览。
 * @param {object} row 文件信息
 * @param {number} currentIndex 当前文件索引
 * @param {array} fileList 文件列表
 */
	// handleWPSView (row, currentIndex, ispreview) {
	handleWPSView (row) {
		// if (!ispreview) {
		// 	return Message.warning({ message: '抱歉，您没有此文件夹的预览权限' })
		// }
		Vue.prototype.$openDialog2.wpsPreviewFile({ fileInfo: row })
		return false
	}
	,
	/**
	 * 文件预览
	 * @description 若当前点击的为文件夹，则进入文件夹内部；若是文件，则进行相应的预览。
	 * @param {object} row 文件信息
	 * @param {number} currentIndex 当前文件索引
	 * @param {array} fileList 文件列表
	 */
	// handleFileNameClick (row, currentIndex, ispreview) {
	handleFileNameClick (row) {
		// 如果当前文件在回收站中，则不允许预览
		if (row.deleteName && row.delete_date) {
			return false
		}
		// 若是文件夹则进入该文件夹
		if (row.is_dir) {
			if (Number(router.currentRoute.query.fileType) !== 6) {// 回收站页面不允许打开文件夹
				const rootId = router.currentRoute.query.rootId ? router.currentRoute.query.rootId : row.id
				router.push({
					query: {
						file_path: `${row.file_path === '/' ? '' : row.file_path}/${row.file_name}`,
						fileType: 0,
						folderId: row.id,
						pathId: router.currentRoute.query.pathId + '/' + row.id,
						rootId
					}
				})
			}
		}
		// 若是文件，则进行相应的预览
		else {
			// if (!ispreview) {
			// 	Message.warning({ message: '抱歉，您没有此文件夹的预览权限' })
			// 	return false
			// }
			console.log(row, 'libs/file/index 111行');
			// 可以在这里通过row 判断要预览的文件类型
			Vue.prototype.$openDialog2.previewFile({ fileInfo: row })
			return false
		}
	},
	/**
	 * 文件名称拼接，包括文件名称 + 文件后缀
	 * @param {object} file 文件信息
	 * @param {boolean} isHighlight 是否需要展示高亮效果，默认不需要
	 * @returns {string} 完整文件名称
	 */
	getFileNameComplete (file, isHighlight = false) {
		return isHighlight === true && file.highlightFields
			? `${file.highlightFields}${file.is_dir === 0 && file.extend ? '' : ''
			}`
			: `${file.file_name}${file.is_dir === 0 && file.extend ? '' : ''
			}`
	},
	/**
	 * 文件类型
	 * @param {object} file 文件信息
	 */
	getFileType (file) {
		return file.is_dir === 1
			? '文件夹'
			: file.extend
				? file.extend
				: '文件'
	}
}

export default fileFunction
